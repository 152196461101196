import { makeStyles } from "@material-ui/core";
import * as React from "react";
import { useState } from "react";
import {
  Create,
  FileField,
  FileInput,
  ImageField,
  ImageInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useGetList,
} from "react-admin";
import TranslationInputs from "../../components/translationInputs/translationInputs.component";
import { TimePeriod } from "../../shared/interfaces/timePeriod.interface";
import GetTimePeriodRange from "../../shared/services/timePeriod.services";
import { DisplayStatus } from "./enums/display-status.enum";
import { validateAoi } from "./services/validate-aoi.service";

const useStyles = makeStyles({
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
});

export const AoiCreate = (props: any) => {
  const classes = useStyles();
  const timePeriods = useGetList<TimePeriod>(
    "timeperiods",
    { page: 1, perPage: 100 },
    { field: "id", order: "ASC" },
    {}
  );
  const [displayValue, setDisplayValue] = useState(DisplayStatus.Display);
  const [selectedTimePeriodId, setSelectedTimePeriodId] = useState<number>(0);
  const selectedTimePeriod = GetTimePeriodRange(
    selectedTimePeriodId,
    timePeriods.data || {}
  );
  const displayOptions = Object.keys(DisplayStatus).map((key) => ({
    id: DisplayStatus[key],
    name: key,
  }));

  return (
    <Create {...props}>
      <SimpleForm variant="outlined" margin="none" validate={validateAoi}>
        <TextInput
          source="name"
          validate={[required()]}
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source="description"
          rows={8}
          multiline={true}
          fullWidth={true}
        />
        <ReferenceInput
          label="Category"
          source="areaOfInterestCategoryId"
          reference="aoicategories"
        >
          <SelectInput optionText="name" validate={[required()]} />
        </ReferenceInput>
        <ReferenceArrayInput
          label="Areas"
          source="areas"
          reference="areas"
          target="id"
          parse={(ids) => ids?.map((id) => ({ id }))}
          format={(areas) => areas?.map((b) => b.id)}
        >
          <SelectArrayInput optionText="name" validate={[required()]} />
        </ReferenceArrayInput>
        <FileInput
          source="geojson"
          label="Geo-Json file"
          accept=".geojson"
          validate={[required()]}
        >
          <FileField source="src" title="title" />
        </FileInput>
        <SelectInput
          source="display"
          choices={displayOptions}
          onChange={(e) => setDisplayValue(e.target.value as DisplayStatus)}
          validate={[required()]}
        ></SelectInput>
        {displayValue === DisplayStatus.Scheduled && (
          <ReferenceInput
            label="Time period"
            source="timePeriodId"
            reference="timeperiods"
            onChange={(e) => setSelectedTimePeriodId(e.target.value)}
          >
            <SelectInput
              optionsText="name"
              helperText={selectedTimePeriod}
            ></SelectInput>
          </ReferenceInput>
        )}
        <ImageInput source="medias" accept="image/*" multiple={true}>
          <ImageField source="fullUrl" title="title" />
        </ImageInput>

        <TranslationInputs {...props}>
          <TextInput
            source="name_en"
            label="Name eng"
            formClassName={classes.inlineBlock}
          />
          <TextInput
            source="description_en"
            label="Description eng"
            rows={8}
            multiline={true}
            fullWidth={true}
          />
        </TranslationInputs>
      </SimpleForm>
    </Create>
  );
};
