import { makeStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    ImageInput,
    SelectInput,
    ImageField,
    ReferenceArrayInput,
    ReferenceInput,
    SelectArrayInput,
    FileInput,
    FileField,
    required,
    number,
    DateTimeInput,
} from 'react-admin';
import TranslationInputs from "../../components/translationInputs/translationInputs.component";

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});

export const PathCreate = (props: any) => {

    return (
        <Create {...props}>
            <SimpleForm variant="outlined" margin="none">
                <TextInput source="name" validate={[required()]}/>
                <TextInput source="number" validate={[required()]}/>
                <TextInput source="length" label="Length (km)" validate={[required(), number()]}/>
                <TextInput source="start" label="start name" />
                <TextInput source="end" label="end name" />
                <DateTimeInput source="groomedAt" defaultValue={new Date()} validate={[required()]} />
                <SelectInput source="difficulty" validate={[required()]} choices={[
                    { id: 'veryEasy', name: 'very easy' },
                    { id: 'easy', name: 'easy' },
                    { id: 'medium', name: 'medium' },
                    { id: 'hard', name: 'hard' },
                ]} />
                <SelectInput source="status" validate={[required()]} initialValue={'open'} choices={[
                    { id: 'open', name: 'open' },
                    { id: 'closed', name: 'closed' },
                    // { id: 'freshlyGroomed', name: 'freshly groomed' },
                ]} />
                <ReferenceInput label="Category" source="pathCategoryId" reference="pathcategories">
                    <SelectInput optionText="name" validate={[required()]}/>
                </ReferenceInput>
                <ReferenceArrayInput
                    label="Areas"
                    source="areas"
                    reference="areas"
                    target="id"
                    parse={ids => ids?.map(id => ({ id }))}
                    format={areas => areas?.map(b => b.id)}
                >
                    <SelectArrayInput optionText="name" validate={[required()]}/>
                </ReferenceArrayInput>
                <ReferenceArrayInput label="Tags" source="tag_ids" reference="tags" allowEmpty>
                    <SelectArrayInput optionText="name" />
                </ReferenceArrayInput>
                <FileInput source="gpx" label="GPX file" accept=".gpx" validate={[required()]}>
                    <FileField source="src" title="title" />
                </FileInput>
                <TextInput source="description" rows={8} multiline={true} fullWidth={true} />
                <ImageInput source="medias" accept="image/*" multiple={true}>
                    <ImageField source="fullUrl" title="title" />
                </ImageInput>
                <TranslationInputs {...props}>
                    <TextInput source="name_en" label="Name eng" fullWidth />
                    <TextInput source="start_en" label="Start eng" fullWidth />
                    <TextInput source="end_en" label="End eng" fullWidth />
                    <TextInput source="description_en" label="Description eng" rows={8} multiline fullWidth />
                </TranslationInputs>
            </SimpleForm>
        </Create>
    );
}