import { makeStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  SelectInput,
  ImageField,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  FileInput,
  FileField,
  required,
  number,
  DateTimeInput,
} from "react-admin";
import { Status } from "../../../shared/enums/status.enum";
import { Zone } from "./enums/zone.enum";

const useStyles = makeStyles({
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
});

export const SubPathCreate = (props: any) => {
  const zoneOptions = Object.keys(Zone).map((key) => ({
    id: Zone[key],
    name: key,
  }));

  const statusOptions = Object.keys(Status).map((key) => ({
    id: Status[key],
    name: key,
  }));

  return (
    <Create {...props}>
      <SimpleForm variant="outlined" margin="none">
        <TextInput source="start" label="start number" />
        <TextInput source="end" label="end number" />
        <DateTimeInput
          source="groomedAt"
          defaultValue={new Date()}
          validate={[required()]}
        />
        <SelectInput
          source="zone"
          validate={[required()]}
          choices={zoneOptions}
        />
        <ReferenceArrayInput
          label="Areas"
          source="areas"
          reference="areas"
          target="id"
          parse={(ids) => ids?.map((id) => ({ id }))}
          format={(areas) => areas?.map((b) => b.id)}
        >
          <SelectArrayInput optionText="name" validate={[required()]} />
        </ReferenceArrayInput>
        <ReferenceArrayInput label="Tags" source="tag_ids" reference="tags" allowEmpty>
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <SelectInput
          source="status"
          validate={[required()]}
          initialValue={"open"}
          choices={statusOptions}
        />
        <FileInput
          source="gpx"
          label="GPX file"
          accept=".gpx"
          validate={[required()]}
        >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};
