import { makeStyles } from "@material-ui/core";
import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  DateInput,
  SelectInput,
  ReferenceInput,
  SelectArrayInput,
  ReferenceArrayInput,
  FileField,
  FileInput,
  required,
  number,
  DateTimeInput,
  BooleanInput,
} from "react-admin";
import TranslationInputs from "../../components/translationInputs/translationInputs.component";
import { CustomToolbar } from "./../../components/customtoolbar/customToolbar.component";

const useStyles = makeStyles({
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
});

export const PathEdit = (props: any) => {
  const classes = useStyles();

  return (
    <Edit {...props}>
      <SimpleForm variant="outlined" margin="none" toolbar={<CustomToolbar />}>
        <TextInput source="name" validate={[required()]} />
        <TextInput source="number" validate={[required()]} />
        <TextInput
          source="length"
          label="Length (km)"
          validate={[required(), number()]}
        />
        <TextInput source="start" label="start name" />
        <TextInput source="end" label="end name" />
        <DateTimeInput source="groomedAt" validate={[required()]} />
        <TextInput source="lastGPXFilename" label="Current GPX file" disabled />
        <SelectInput
          source="difficulty"
          validate={[required()]}
          initialValue={"medium"}
          choices={[
            { id: "veryEasy", name: "very easy" },
            { id: "easy", name: "easy" },
            { id: "medium", name: "medium" },
            { id: "hard", name: "hard" },
          ]}
        />
        <SelectInput
          source="status"
          validate={[required()]}
          initialValue={"open"}
          choices={[
            { id: "open", name: "open" },
            { id: "closed", name: "closed" },
          ]}
        />
        <ReferenceInput
          label="Category"
          source="pathCategoryId"
          reference="pathcategories"
        >
          <SelectInput optionText="name" validate={[required()]} />
        </ReferenceInput>
        <ReferenceArrayInput
          label="Areas"
          source="areas"
          reference="areas"
          target="id"
          parse={(ids) => ids?.map((id) => ({ id }))}
          format={(areas) => areas?.map((b) => b.id)}
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          label="Tags"
          source="tag_ids"
          reference="tags"
          allowEmpty
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <FileInput source="gpx" label="GPX file" accept=".gpx">
          <FileField source="src" title="title" />
        </FileInput>
        <TextInput
          source="description"
          rows={8}
          multiline={true}
          fullWidth={true}
        />
        <ImageInput source="medias" accept="image/*" multiple={true}>
          <ImageField source="fullUrl" title="title" />
        </ImageInput>
        <TranslationInputs {...props}>
          <TextInput source="name_en" label="Name eng" fullWidth />
          <TextInput source="start_en" label="Start eng" fullWidth />
          <TextInput source="end_en" label="End eng" fullWidth />
          <TextInput
            source="description_en"
            label="Description eng"
            rows={8}
            multiline
            fullWidth
          />
        </TranslationInputs>
        <DateInput
          disabled
          source="createdAt"
          formClassName={classes.inlineBlock}
        />
        <DateInput
          disabled
          source="updatedAt"
          formClassName={classes.inlineBlock}
        />
        <BooleanInput source="visible" label="Visible" defaultValue={true} />
        {/* 
        <BooleanInput
          source="visible_web"
          label="Visible on web"
          defaultValue={true}
        /> */}
      </SimpleForm>
    </Edit>
  );
};
