import { makeStyles } from "@material-ui/core";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  Edit,
  FileField,
  FileInput,
  ImageField,
  ImageInput,
  Loading,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useGetList,
  useGetOne,
} from "react-admin";
import { CustomToolbar } from "../../components/customtoolbar/customToolbar.component";
import TranslationInputs from "../../components/translationInputs/translationInputs.component";
import GetTimePeriodRange from "../../shared/services/timePeriod.services";
import { TimePeriod } from "../../shared/interfaces/timePeriod.interface";
import { DisplayStatus } from "./enums/display-status.enum";
import { validateAoi } from "./services/validate-aoi.service";

const useStyles = makeStyles({
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
});

export const AoiEdit = (props: any) => {
  const classes = useStyles();
  const timePeriods = useGetList<TimePeriod>(
    "timeperiods",
    { page: 1, perPage: 100 },
    { field: "id", order: "ASC" },
    {}
  );
  const [selectedTimePeriodId, setSelectedTimePeriodId] = useState<number>(0);
  const [displayValue, setDisplayValue] = useState(DisplayStatus.Display);
  const aoi = useGetOne("aois", props.id);
  const selectedTimePeriod = GetTimePeriodRange(
    selectedTimePeriodId,
    timePeriods.data as any
  );
  const displayOptions = Object.keys(DisplayStatus).map((key) => ({
    id: DisplayStatus[key],
    name: key,
  }));

  useEffect(() => {
    setSelectedTimePeriodId(aoi?.data?.timePeriodId);
    setDisplayValue(aoi?.data?.display);
  }, [aoi.loaded]);

  if (!timePeriods.loaded && !aoi.loaded) {
    return <Loading></Loading>;
  }
  return (
    <Edit {...props}>
      <SimpleForm
        variant="outlined"
        margin="none"
        toolbar={<CustomToolbar />}
        validate={validateAoi}
      >
        <TextInput
          source="name"
          validate={[required()]}
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source="description"
          rows={8}
          multiline={true}
          fullWidth={true}
        />
        <ReferenceInput
          label="Category"
          source="areaOfInterestCategoryId"
          reference="aoicategories"
        >
          <SelectInput optionText="name" validate={[required()]} />
        </ReferenceInput>
        <ReferenceArrayInput
          label="Areas"
          source="areas"
          reference="areas"
          target="id"
          parse={(ids) => ids?.map((id) => ({ id }))}
          format={(areas) => areas?.map((b) => b.id)}
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <TextInput
          source="geoJsonFilename"
          label="Current GeoJson file"
          disabled
        />
        <FileInput source="geojson" label="New Geo-Json file" accept=".geojson">
          <FileField source="src" title="title" />
        </FileInput>
        <SelectInput
          source="display"
          choices={displayOptions}
          onChange={(e) => setDisplayValue(e.target.value as DisplayStatus)}
          validate={[required()]}
        ></SelectInput>
        {displayValue === DisplayStatus.Scheduled && (
          <ReferenceInput
            label="Time period"
            source="timePeriodId"
            reference="timeperiods"
            onChange={(e) => setSelectedTimePeriodId(e.target.value)}
          >
            <SelectInput
              optionsText="name"
              helperText={selectedTimePeriod}
            ></SelectInput>
          </ReferenceInput>
        )}
        <ImageInput source="medias" accept="image/*" multiple={true}>
          <ImageField source="fullUrl" title="title" />
        </ImageInput>
        <TranslationInputs {...props}>
          <TextInput
            source="name_en"
            label="Name eng"
            formClassName={classes.inlineBlock}
          />
          <TextInput
            source="description_en"
            label="Description eng"
            rows={8}
            multiline={true}
            fullWidth={true}
          />
        </TranslationInputs>
      </SimpleForm>
    </Edit>
  );
};
