import { makeStyles } from "@material-ui/core";
import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  DateInput,
  required,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceInput,
  SelectInput,
  ImageField,
  ImageInput,
  minValue,
  maxValue,
  BooleanInput,
} from "react-admin";
import TranslationInputs from "../../components/translationInputs/translationInputs.component";
import { CustomToolbar } from "./../../components/customtoolbar/customToolbar.component";

const useStyles = makeStyles({
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
});

export const PoiEdit = (props: any) => {
  const classes = useStyles();

  return (
    <Edit {...props}>
      <SimpleForm variant="outlined" margin="none" toolbar={<CustomToolbar />}>
        <TextInput
          source="name"
          validate={[required()]}
          formClassName={classes.inlineBlock}
        />
        {/*<TextInput source="icon" />*/}
        <NumberInput
          source="latitude"
          validate={[required(), minValue(-90), maxValue(90)]}
          min={-90}
          max={90}
        />
        <NumberInput
          source="longitude"
          validate={[required(), minValue(-180), maxValue(180)]}
          min={-180}
          max={180}
        />
        <NumberInput source="elevation" validate={[required()]} />
        <TextInput source="url" />
        <TextInput
          source="description"
          rows={8}
          multiline={true}
          fullWidth={true}
        />
        <ReferenceInput
          label="Category"
          source="pointOfInterestCategoryId"
          reference="poicategories"
          sort={{ field: "name", order: "ASC" }}
        >
          <SelectInput optionText="name" validate={[required()]} />
        </ReferenceInput>
        <ReferenceArrayInput
          label="Path categories"
          source="pathCategories"
          reference="pathcategories"
          target="id"
          parse={(ids) => ids?.map((id) => ({ id }))}
          format={(pathCategories) => pathCategories?.map((b) => b.id)}
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          label="Areas"
          source="areas"
          reference="areas"
          target="id"
          parse={(ids) => ids?.map((id) => ({ id }))}
          format={(areas) => areas?.map((b) => b.id)}
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <ImageInput source="medias" accept="image/*" multiple={true}>
          <ImageField source="fullUrl" title="title" />
        </ImageInput>
        <TranslationInputs {...props}>
          <TextInput
            source="name_en"
            label="Name eng"
            formClassName={classes.inlineBlock}
          />
          <TextInput
            source="description_en"
            label="Description eng"
            rows={8}
            multiline={true}
            fullWidth={true}
          />
        </TranslationInputs>
        <DateInput
          disabled
          source="createdAt"
          formClassName={classes.inlineBlock}
        />
        <DateInput
          disabled
          source="updatedAt"
          formClassName={classes.inlineBlock}
        />
        <BooleanInput
          source="visible"
          label="Visible in app"
          defaultValue={true}
        />
        <BooleanInput
          source="visible_web"
          label="Visible on web"
          defaultValue={true}
        />
      </SimpleForm>
    </Edit>
  );
};
