import * as React from "react";
import { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import {
  BulkDeleteButton,
  BulkDeleteWithConfirmButton,
  Filter,
  ReferenceInput,
  SelectInput,
  TextInput,
} from "react-admin";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  BooleanField,
  DateField,
  ArrayField,
  SingleFieldList,
  ChipField,
} from "react-admin";
import { PoiEdit } from "./poi.edit.component";

const PoiFilter = (props) => (
  <Filter {...props}>
    <TextInput
      label="Search"
      variant={"outlined"}
      source="q"
      alwaysOn
      resettable
    />
    <ReferenceInput
      label="Area"
      source="areas"
      target="id"
      reference="areas"
      allowEmpty={false}
      alwaysOn
      resettable
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      label="Category"
      source="pointOfInterestCategoryId"
      target="id"
      reference="poicategories"
      allowEmpty={false}
      alwaysOn
      resettable
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export const PoiList = (props: any) => {
  return (
    <List {...props} filters={<PoiFilter />} exporter={false}>
      <Datagrid rowClick="edit" expand={PoiEdit}>
        <BooleanField source="visible" label="Visible in app?" />
        <BooleanField source="visible_web" label="Visible on web?" />
        <TextField source="name" />
        <TextField
          source="pointOfInterestCategory.name"
          label="Category"
          sortable={false}
        />
        <ArrayField source="pathCategories" sortable={false}>
          <SingleFieldList linkType={false}>
            <ChipField clickable={false} source="name" color={"primary"} />
          </SingleFieldList>
        </ArrayField>
        <ArrayField source="areas" sortable={false}>
          <SingleFieldList linkType={false}>
            <ChipField clickable={false} source="name" color={"secondary"} />
          </SingleFieldList>
        </ArrayField>
        <DateField
          source="createdAt"
          options={{ day: "2-digit", month: "short", year: "numeric" }}
        />
        <DateField
          source="updatedAt"
          options={{ day: "2-digit", month: "short", year: "numeric" }}
        />
      </Datagrid>
    </List>
  );
};
