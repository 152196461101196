import { makeStyles } from "@material-ui/core";
import * as React from "react";
import {
  BooleanInput,
  DateInput,
  DateTimeInput,
  Edit,
  FileField,
  FileInput,
  ReferenceArrayInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { Status } from "../../../shared/enums/status.enum";
import { CustomToolbar } from "./../../../components/customtoolbar/customToolbar.component";
import { Zone } from "./enums/zone.enum";

const useStyles = makeStyles({
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
});

export const SubPathEdit = (props: any) => {
  const classes = useStyles();
  const zoneOptions = Object.keys(Zone).map((key) => ({
    id: Zone[key],
    name: key,
  }));

  const statusOptions = Object.keys(Status).map((key) => ({
    id: Status[key],
    name: key,
  }));
  return (
    <Edit {...props}>
      <SimpleForm variant="outlined" margin="none" toolbar={<CustomToolbar />}>
        <TextInput source="start" label="start number" />
        <TextInput source="end" label="end number" />
        <DateTimeInput
          source="groomedAt"
          defaultValue={new Date()}
          validate={[required()]}
        />
        <SelectInput
          source="zone"
          validate={[required()]}
          choices={zoneOptions}
        />
        <ReferenceArrayInput
          label="Areas"
          source="areas"
          reference="areas"
          target="id"
          parse={(ids) => ids?.map((id) => ({ id }))}
          format={(areas) => areas?.map((b) => b.id)}
        >
          <SelectArrayInput optionText="name" validate={[required()]} />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          label="Tags"
          source="tag_ids"
          reference="tags"
          allowEmpty
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <SelectInput
          source="status"
          validate={[required()]}
          initialValue={"open"}
          choices={statusOptions}
        />
        <TextInput source="lastGPXFilename" label="Current GPX file" disabled />
        <FileInput source="gpx" label="GPX file" accept=".gpx">
          <FileField source="src" title="title" />
        </FileInput>
        <DateInput
          disabled
          source="createdAt"
          formClassName={classes.inlineBlock}
        />
        <DateInput
          disabled
          source="updatedAt"
          formClassName={classes.inlineBlock}
        />
        <BooleanInput source="visible" label="Visible" defaultValue={true} />
        {/*<BooleanInput
          source="visible_web"
          label="Visible on web"
          defaultValue={true}
        /> */}
      </SimpleForm>
    </Edit>
  );
};
