export const darkTheme = {
    palette: {
        primary: {
            main: '#90caf9',
        },
        secondary: {
            main: '#FBBA72',
        },
        type: 'dark', // Switching the dark mode on is a single property value change.
    },
    overrides: {
        MuiAppBar: {
            colorSecondary: {
                color: '#ffffffb3',
                backgroundColor: '#616161e6',
            },
        },
    },
};

export const lightTheme = {
    palette: {
        primary: {
            main: '#4caf50',
            contrastText: '#fff',
        },
        secondary: {
            light: '#5f5fc4',
            main: '#283593',
            dark: '#001064',
            contrastText: '#fff',
        },
        background: {
            default: '#fcfcfe',
        },
    },
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Arial',
            'sans-serif',
        ].join(','),
    },
    shape: {
        borderRadius: 10,
    },
    overrides: {
        RaLayout: {
            content: {
                paddingTop: '24px',
                paddingLeft: '24px'
            }
        },
        RaSidebar: {
            root: {
                backgroundColor: 'white',
                borderRight: '1px solid #e0e0e3',
                marginRight: '24px',
            },
        },
        MuiChip: {
            root: {
                color: '#fff',
                backgroundColor: '#4caf50',
            }
        },
        RaAutocompleteArrayInput: {
            chip: {
                backgroundColor: '#4caf50',
            }
        },
        RaDatagrid: {
            rowOdd: {
                backgroundColor: '#fafafa'
            }
        },
        RaMenuItemLink: {
            root: {
                '&& > .MuiSvgIcon-root': {
                    marginLeft: 'auto',
                }
                // borderLeft: '3px solid #fff',
            },
            active: {
                color: '#4caf50',
                '&& .MuiListItemIcon-root': {
                    color: '#4caf50',
                }
                // borderLeft: '3px solid #4f3cc9',
            },

        },
        MuiCollapse: {
            root: {
                background: '#fafafa',
                borderTop: '1px solid #e0e0e3',
                borderBottom: '1px solid #e0e0e3',
            }
        },
        MuiPaper: {
            elevation1: {
                boxShadow: 'none',
            },
            root: {
                border: '1px solid #e0e0e3',
                backgroundClip: 'padding-box',
            },
        },
        MuiButton: {
            contained: {
                backgroundColor: '#fff',
                color: '#4f3cc9',
                boxShadow: 'none',
            },
        },
        MuiAppBar: {
            colorSecondary: {
                color: '#808080',
                backgroundColor: '#fff',
            },
        },
        RaFileInput: {
            dropZone: {
                backgroundColor: '#fcfcfe !important',
                border: '1px solid #eaeaea',
                borderRadius: '10px'
            }
        },
        MuiLinearProgress: {
            colorPrimary: {
                backgroundColor: '#f5f5f5',
            },
            barColorPrimary: {
                backgroundColor: '#d7d7d7',
            },
        },
        MuiFormHelperText: {
            contained: {
                marginBottom: '5px',
            }
        },
        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                '&$disabled': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
            },
        },
    },
};