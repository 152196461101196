import Map from "@material-ui/icons/Map";
import { MouseEvent, useState } from "react";
import { Confirm, fetchUtils } from "react-admin";

export const TriggerButton = () => {
  const mapAppId = process.env.REACT_APP_MAP_DO_APP_ID;
  const digitalOceanOAuthToken = process.env.REACT_APP_DO_OAUTH_TOKEN;

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const triggerAppBuild = () => {
    const url = `https://api.digitalocean.com/v2/apps/${mapAppId}/deployments`;
    const options = {
      method: "post",
      headers: new Headers({
        Authorization: `Bearer ${digitalOceanOAuthToken}`,
      }),
    };

    const body = { force_build: true };
    setConfirmationOpen(false);

    return fetchUtils.fetchJson(url, options), body;
  };

  return (
    <>
      <Confirm
        isOpen={confirmationOpen}
        content={"Vill du generera ett nytt bygge av webbkartan?"}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={() => triggerAppBuild()}
        title={"Rita om kartan?"}
        cancel="Nej"
        confirm="Ja"
      />
      <button
        className="MuiButtonBase-root MuiIconButton-root MuiIconButton-colorInherit"
        aria-label="Rita"
        title="Rita"
        type="button"
        tabIndex={0}
        onClick={() => setConfirmationOpen(true)}
      >
        <span className="MuiIconButton-label">
          <Map />
        </span>
        <span className="MuiTouchRipple-root"></span>
      </button>
    </>
  );
};
