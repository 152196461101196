import * as React from "react";
import { useSelector } from "react-redux";
import { Layout, Sidebar, AppBar } from "react-admin";
import { UserMenu } from "./../components/userMenu/userMenu.component";
import Menu from "./../components/menu/menu.component";
import { lightTheme } from "./../theme";
import { TriggerButton } from "../components/triggerButton/triggerButton.component";

const CustomSidebar = (props: any) => <Sidebar {...props} size={200} />;
const CustomAppBar = (props: any) => (
  <AppBar {...props} userMenu={<UserMenu />}>
    <div style={{width: "90%"}}></div>
    <TriggerButton></TriggerButton>
  </AppBar>
);

export const CustomLayout = (props: any) => (
  <Layout
    {...props}
    appBar={CustomAppBar}
    sidebar={CustomSidebar}
    menu={Menu}
    theme={lightTheme}
  />
);

export default CustomLayout;
